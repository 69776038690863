import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GregorianTimePipe } from '../gregorian-time.pipe';
import { RemacDropZoneDirective } from '../remac-drop-zone.directive';
import { FileSizePipe } from '../file-size.pipe';





@NgModule({
  declarations: [GregorianTimePipe, RemacDropZoneDirective, FileSizePipe],
  imports: [
    CommonModule,
    
  ],
  exports: [GregorianTimePipe, RemacDropZoneDirective,FileSizePipe, ]
})
export class SharedModule { }
