// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCzjxmaHBLkhV1LyGdFLcKasRz92jyfo0E",
    authDomain: "remac-app-203515.firebaseapp.com",
    databaseURL: "https://remac-app-203515.firebaseio.com",
    projectId: "remac-app-203515",
    storageBucket: "remac-app-203515.appspot.com",
    messagingSenderId: "516876616562",
    vapidKey: "BGT5O1njV4xE_YpfYzb5JFtDCefgR50fjPxaF0asvxU7087nhqbWrvGsRiLD8Qq4BBh_7ppc1hLklkf3K8FDLus"
    
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
