import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gregorianTime'
})

export class GregorianTimePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    console.log('value: ',value);
    let unix = value - 62167219200; 
    console.log('Converting unix to date: ',unix);
    return new Date(unix * 1000);
  }

}
