import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user: firebase.User;
  userDoc: any;

  authState = new BehaviorSubject(false);
  
  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
    this.userDoc = null;
    afAuth.authState.subscribe(user => {
      console.log('assigned logged in user: ',user);
      
      this.user = user;
      console.log('authservoce: set user to: ',user);
      if(user)
      afs.doc(`users/${user.uid}`).valueChanges().subscribe(u => {
        this.userDoc = u;
        console.log('auth.service set userDoc to:',u);
        this.authState.next(true);
      })
    });

   }

   loginUser(email: string, password: string): Promise<firebase.auth.UserCredential>{
    //console.log('Sign in with email');
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
    //return firebase.auth().signInAndRetrieveDataWithEmailAndPassword(email, password);
  }

  logoutUser(): Promise<void> {
    this.user = null;
    this.userDoc = null;
    this.authState.next(false);
    this.router.navigate(['login']);
    return firebase.auth().signOut();
  }

  resetPasswordByEmail(email: string){
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }

  get authenticated(): boolean {
    if(this.user == null || this.userDoc == null){
      return false;
    } else {
      return true;
    }
  }
  
  getUserDoc(): Promise<any> {
    //console.log('returning observable of useDoc: ',of(this.userDoc));
    return new Promise((resolve, reject) => {
      if(this.userDoc){
        resolve(this.userDoc);
      } else {
        this.afs.doc(`users/${this.user.uid}`).valueChanges().subscribe(u => {
          this.userDoc = u;
          resolve(this.userDoc);
        })
      }
    })
    
  }
}
