import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MasterAdminGuard } from './services/master-admin.guard';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'inbox',
        pathMatch: 'full'
      },
      {
        path: 'inbox',
        loadChildren: () => import('./inbox/inbox.module').then( m => m.InboxPageModule)
      },
      {
        path: 'sent',
        loadChildren: () => import('./sent/sent.module').then( m => m.SentPageModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
      },
      {
        path: 'newfax',
        loadChildren: () => import('./newfax/newfax.module').then( m => m.NewfaxPageModule)
      },
      {
        path: 'outbox',
        loadChildren: () => import('./outbox/outbox.module').then( m => m.OutboxPageModule)
      },
    ]
  },
  
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  

  

  
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
