import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    {
      title: 'New Fax',
      url: '/newfax',
      icon: 'add-outline'
    },
    {
      title: 'Inbox',
      url: '/inbox',
      icon: 'mail-outline'
    },
    {
      title: 'Sent',
      url: '/sent',
      icon: 'file-tray-full-outline'
    },
    {
      title: 'Outbox',
      url: '/outbox',
      icon: 'file-tray-outline'
    },
    {
      title: 'Settings',
      url: '/settings',
      icon: 'settings-outline'
    },
    
  ];

  isLoggedin: boolean;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private auth: AuthService,
    private router: Router
  ) {
    this.isLoggedin = false;
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.auth.authState.subscribe(state => {
        if(state){
          this.isLoggedin = true;
          this.router.navigate(['inbox']);
        } else {
          this.isLoggedin = false;
          this.router.navigate(['login']);
        }
      })
    });
  }
}
